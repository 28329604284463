import React from "react"
import Styled from "./styled"

const TextBlock = props => {
  return (
    <Styled.Textblock
      category={ props.input.headline.text }
    >
      <h3>{ props.input.headline.text }</h3>
      <div dangerouslySetInnerHTML={{ __html: props.input.body_text1.html }} />
    </Styled.Textblock>
  )
}

export default TextBlock