import React from "react"
import Styled from "./styled"
import TextBlock from "./text-block"
import AnimatedLink from "../animated-link"

const Info = props => {

  const leftColumnContent = props.input.left_column && props.input.left_column.map((node, index) => {
    return (
      <TextBlock key={index} input={ node } />
    )
  })

  const rightColumnContent = props.input.right_column && props.input.right_column.map((node, index) => {
    return (
      <TextBlock key={index} input={ node } />
    )
  })

  return (
    <Styled.Info>

      <Styled.InfoContent>
        <div>
          { leftColumnContent }
        </div>
        <div>
          { rightColumnContent }
        </div>
      </Styled.InfoContent>

      <Styled.LinkContainer>
        <AnimatedLink
          isInternalLink={ false }
          target="mailto:hello@fabiobiesel.com"
          linkText="Go!"
        />
      </Styled.LinkContainer>

    </Styled.Info>
  )
}

export default Info