import styled, { keyframes } from "styled-components"
import themeSettings from "../../styles/theme"

const wobble = keyframes`
  0% { transform: skew(0) }
  20% { transform: skew(-10deg) }
  40% { transform: skew(10deg) }
  60% { transform: skew(-5deg) }
  80% { transform: skew(5deg) }
  90% { transform: skew(2deg) }
  100% { transform: skew(0) }
`

const Hero = styled.section`
  box-sizing: border-box;
  width: 100vw;
  max-width: 100%;
  height: auto;
  margin-top: ${themeSettings.navHeight}px;
  padding: ${themeSettings.margins.page};

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media screen and (max-width: 414px) {
    padding: ${themeSettings.mobileMargins.page};
  }

  & > div {
    line-height: calc(${themeSettings.lineHeights.large} * 1.04);
    font-size: calc(${themeSettings.fontSizes.large} * 0.8);

    @media screen and (max-width: 1440px) {
      font-size: calc(${themeSettings.fontSizes.large} * 0.5);
    }

    @media screen and (max-width: 720px) {
      font-size: calc(${themeSettings.fontSizes.base} * 1.4);
      line-height: ${themeSettings.lineHeights.mobileLarge};
    }

    @media screen and (max-width: 375px) {
      font-size: calc(${themeSettings.fontSizes.base} * 1.2);
    }
  }
`

const HeroImage = styled.picture`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  & img {
    width: 100%;
    max-width: 100%;
    height: auto;
    margin-bottom: ${themeSettings.margins.page};
    object-fit: contain;
    vertical-align: bottom;
  }
`

const Info = styled.section`
  box-sizing: border-box;
  width: 100vw;
  max-width: 100%;
  /* min-height: calc(100vh - ${themeSettings.navHeight}px); */

  padding: ${themeSettings.margins.large} calc(${themeSettings.margins.page} * 4);

  @media screen and (max-width: 968px) {
    padding: ${themeSettings.margins.large} ${themeSettings.margins.page};
    min-height: auto;
    height: auto;
  }

  @media screen and (max-width: 414px) {
    padding: ${themeSettings.margins.large} ${themeSettings.mobileMargins.page};
  }
`

const InfoContent = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: calc(${themeSettings.margins.page} * 4);

  @media screen and (max-width: 968px) {
    display: block;
  }
`

const LinkContainer = styled.div`
  width: 100%;
  margin-top: ${themeSettings.margins.large};
  display: flex;
  align-items: flex-start;
  justify-content: center;
`

const Textblock = styled.div`
  width: 100%;
  margin-bottom: calc(${themeSettings.margins.page} * 1.6);
  
  & > h3 {
    width: 100%;
    text-align: center;
    text-transform: uppercase;
  }

  & > div {
    & > p {
      @media screen and (max-width: 968px) {
        text-align: ${
          props => props.category === "Contact" ? "center"
          : props.category === "Follow" ? "center"
          : "inherit"
        };
      }
      & > a {
        white-space: nowrap;
        display: inline-block;
        &:hover {
          animation: 0.8s ${wobble} ease-out;
        }
      }
    }
  }

`

const ProfileImageContainer = styled.div`
  position: relative;
  width: 100vw;
  max-width: 100%;

  @media screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column-reverse;
  }
`

const ProfileImage = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  picture {
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      vertical-align: bottom;
    }
  }
`

const ProfileImageText = styled.div`
  position: absolute;
  z-index: 2;
  top: calc(${themeSettings.margins.page} * 4);
  left: calc(${themeSettings.margins.page} * 4);
  color: #fff;

  @media screen and (max-width: 1024px) {
    position: initial;
    padding: ${themeSettings.margins.large} ${themeSettings.margins.page};
    color: #000;
  }

  @media screen and (max-width: 414px) {
    padding: ${themeSettings.margins.large} ${themeSettings.mobileMargins.page};
  }

  div {
    width: 30%;
    @media screen and (max-width: 1024px) {
      width: 50%;
    }
    @media screen and (max-width: 414px) {
      width: 100%;
    }
  }
`

const ProfileImageName = styled.p`
  position: absolute;
  width: calc(100% - (20px * 8));
  height: 100%;
  top: 50%;
  left: 50%;
  color: #fff;
  font-size: 100px;
  transform: translate(-50%, -50%);
  z-index: 3;

  picture {
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      vertical-align: bottom;
    }
  }
`

export default { 
  Hero,
  HeroImage,
  Info,
  InfoContent,
  LinkContainer,
  Textblock,
  ProfileImageContainer,
  ProfileImage,
  ProfileImageText,
  ProfileImageName
 }