import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Hero from "./hero"
import Info from "./info"
import Styled from "./styled"

const AboutBody = () => {

  const data = useStaticQuery(graphql`
    query AboutBodyQuery {
      prismicAbout {
        uid
        data {

          # image_desktop {
          #   alt
          #   url
          #   large {
          #     url
          #   }
          # }

          body_text {
            html
          }

          left_column {
            headline {
              text
            }
            body_text1 {
              html
            }
          }

          right_column {
            headline {
              text
            }
            body_text1 {
              html
            }
          }

          image {
            # alt
            url
          }

          image_text {
            html
          }

          image_headline {
            alt
            url
          }

        }
      }
    }  
  `)

  const content = data.prismicAbout.data

  console.log(content)

  return (
    <div>
      <Hero input={ content } />
      <Info input={ content } />
      <Styled.ProfileImageContainer>
        <Styled.ProfileImageText>
          <div dangerouslySetInnerHTML={{ __html: content.image_text.html }} />
        </Styled.ProfileImageText>
        <Styled.ProfileImage>
          <Styled.ProfileImageName>
            <picture>
              <img src={ content.image_headline.url } />
            </picture>
          </Styled.ProfileImageName>
          <picture>
            <img src={ content.image.url } />
          </picture>
        </Styled.ProfileImage>
      </Styled.ProfileImageContainer>
    </div>
  )
}

export default AboutBody