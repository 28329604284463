import React from "react"
import Styled from "./styled"

const Hero = props => {

  return (
    <Styled.Hero>
      {
        props?.input?.image_desktop?.url?.legnth >= 1 && (
          <Styled.HeroImage>
            <img
              alt="About Studio Fabio Biesel"
              src={ props.input.image_desktop.url }
            />
          </Styled.HeroImage>
        )
      }
      <div dangerouslySetInnerHTML={{ __html: props.input.body_text.html }} />
    </Styled.Hero>
  )
}

export default Hero