import React from "react"
import { ThemeProvider } from "styled-components"
import LayoutDefault from "../layouts/default"
import AboutBody from "../components/about-body"

const AboutPage = () => {
  return (
    <ThemeProvider theme={{ mode: "light" }}>
      <LayoutDefault>
        <AboutBody />
      </LayoutDefault>
    </ThemeProvider>
  )
}

export default AboutPage